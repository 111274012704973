import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, clearError } from '../../redux/actions/userActions';
import './Navbar.scss';
import Error from "../../Pages/Modals/ErrorModal";

const Navbar = () => {
    const dispatch = useDispatch();
    const { userData, loading, error } = useSelector((state) => state.user);
   
    useEffect(() => {
        const getTokenFromUrl = () => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('token');  
        };

        const urlToken = getTokenFromUrl();

        if (urlToken) {
            localStorage.setItem("token", urlToken);
        }

        const token = localStorage.getItem("token");

        
        if (token && !userData) {
            dispatch(fetchUserData(token));
        }

        
        if (!token) {
            window.location.href = 'https://auth.play929.com';
        }

    }, [dispatch, userData]);  

    const handleCloseError = () => {
        dispatch(clearError());
    };

    return (
        <>
            <header className="navbar">
                <div className="navbar-content">
                    <div className="balance">
                        {loading ? "Loading..." : userData ? `R${userData.balance}` : ''}
                    </div>

                    <div className="account-number">
                        <div className="label">Acc No:</div>
                        <div className="number">{userData && userData.accountNumber ? userData.accountNumber : ''}</div>
                    </div>
                </div>
            </header>

            {error && <Error errorMessage={error} isOpen={true} onClose={handleCloseError} />}
        </>
    );
};

export default Navbar;
