import React, { useState } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import './refer.scss'; 
import { useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { motion } from 'framer-motion';

function ReferralPage({ showSidebar, active, closeSidebar }) {
  const [referralLink, setReferralLink] = useState('');
  const { accountNumber } = useSelector((state) => state.user.userData) || {};

  const generateReferralLink = () => {
    if (accountNumber) {
      const link = `https://Play929.com/?r=${accountNumber}`;
      setReferralLink(link);
    } else {
      alert('Account number not found. Please check your profile.');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => alert('Copied to clipboard'))
      .catch((err) => console.error('Failed to copy:', err));
  };

  const fadeInAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 } // Delay based on index
    }),
  };

  return (
    <div className="refer">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="container">
        <Navbar showSidebar={showSidebar} />
        
        <motion.p
          className="instructions"
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
          custom={2}
        >
          You earn the same amount as the user you referred deposited!
        </motion.p>

        <motion.p
          className="description"
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
          custom={3}
        >
          Join our referral program to earn rewards by inviting your friends! Simply generate a referral link and share it. When your friends sign up using your link and make their first deposit, you both benefit!
        </motion.p>

        {/* Generate Referral Link Button */}
        <motion.div initial="hidden" animate="visible" variants={fadeInAnimation} custom={4}>
          <Button className ="form_btn" variant="primary" onClick={generateReferralLink}>
            Generate Referral Link
          </Button>
        </motion.div>

        {referralLink && (
          <InputGroup className="mb-3 mt-3">
            <FormControl
              readOnly
              value={referralLink}
              className="referral-input"
            />
            <Button variant="outline-secondary" onClick={copyToClipboard}>
              Copy
            </Button>
          </InputGroup>
        )}

        {/* Testimonials Section */}
        <motion.div
          className="testimonials"
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
          custom={5}
        >
          <h2>What Our Users Say</h2>
          <div className="testimonial">
            <p>"I earned a lot from the referral program! It's so easy to use!"</p>
          </div>
          <div className="testimonial">
            <p>"Sharing my link was a breeze, and I got my rewards quickly!"</p>
          </div>
        </motion.div>

        {/* FAQs Section */}
        <motion.div
          className="faq"
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
          custom={6}
        >
          <h2>Frequently Asked Questions</h2>
          <div className="faq-item">How does the referral program work?</div>
          <div className="faq-answer">You earn rewards for every user that signs up using your referral link.</div>

          <div className="faq-item">Is there a limit to how many referrals I can make?</div>
          <div className="faq-answer">No, you can refer as many friends as you like!</div>

          <div className="faq-item">When will I receive my rewards?</div>
          <div className="faq-answer">Rewards are credited to your account once the referred user completes their first deposit.</div>
        </motion.div>
      </div>
    </div>
  ); 
}

export default ReferralPage;
