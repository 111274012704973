import React, { useState } from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import './security.scss';

const Security = ({ showSidebar, active, closeSidebar }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [securityLogs, setSecurityLogs] = useState([
    { date: '2024-10-13', activity: 'Login from Chrome, New York' },
    { date: '2024-10-12', activity: 'Password changed' },
    { date: '2024-10-10', activity: 'Login from Firefox, Los Angeles' }
  ]);
  const [strength, setStrength] = useState('');
  const [showBackupCodes, setShowBackupCodes] = useState(false);
  const [backupCodes, setBackupCodes] = useState(generateBackupCodes());
  const [activeSessions, setActiveSessions] = useState([
    { device: 'Chrome', location: 'New York', active: true },
    { device: 'Firefox', location: 'Los Angeles', active: false },
  ]);
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Handle password update submission
  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match.');
      return;
    }
    alert('Password updated successfully!');
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  // Toggle Two-Factor Authentication
  const toggle2FA = () => {
    setIs2FAEnabled(!is2FAEnabled);
    alert(`Two-factor authentication ${!is2FAEnabled ? 'enabled' : 'disabled'} successfully!`);
  };

  // Handle password input change for strength checker
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setStrength(checkPasswordStrength(password));
  };

  // Generate backup codes
  function generateBackupCodes() {
    return Array.from({ length: 5 }, () => Math.floor(100000 + Math.random() * 900000).toString());
  }

  // Check password strength
  function checkPasswordStrength(password) {
    const lengthCriteria = password.length >= 8;
    const numberCriteria = /\d/.test(password);
    const lowercaseCriteria = /[a-z]/.test(password);
    const uppercaseCriteria = /[A-Z]/.test(password);
    const specialCharCriteria = /[!@#$%^&*]/.test(password);

    const criteriaMet = [lengthCriteria, numberCriteria, lowercaseCriteria, uppercaseCriteria, specialCharCriteria].filter(Boolean).length;
    
    switch (criteriaMet) {
      case 0:
      case 1:
      case 2:
        return 'Weak';
      case 3:
        return 'Medium';
      case 4:
      case 5:
        return 'Strong';
      default:
        return '';
    }
  }

  // Log out from active session
  const logoutSession = (device) => {
    setActiveSessions(prevSessions => 
      prevSessions.map(session => 
        session.device === device ? { ...session, active: false } : session
      )
    );
    alert(`Logged out from ${device}`);
  };

  return (
    <div className="security-wrapper">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="security-container">
        <Navbar showSidebar={showSidebar} />
        
        <h2 className="security-title">Account Security</h2>

        <div className="security-section">
          <h3>Change Password</h3>
          <form onSubmit={handlePasswordUpdate}>
            <div className="form-group">
              <label>Current Password</label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                aria-label="Current Password"
              />
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type={passwordVisible ? 'text' : 'text'}
                value={newPassword}
                onChange={handlePasswordChange}
                required
                aria-label="New Password"
              />
              <p className={`password-strength ${strength.toLowerCase()}`}>Password Strength: {strength}</p>
              
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                type={passwordVisible ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                aria-label="Confirm New Password"
              />
            </div>
            <button type="submit" className="btn btn-primary">Update Password</button>
          </form>
        </div>

        <div className="security-section">
          <h3>Two-Factor Authentication (2FA)</h3>
          <p>Enhance your account security by enabling 2FA. You will need to provide an extra code when logging in.</p>
          <button onClick={toggle2FA} className={`btn ${is2FAEnabled ? 'btn-danger' : 'btn-success'}`}>
            {is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
          </button>
          {is2FAEnabled && (
            <div>
              <button onClick={() => setShowBackupCodes(!showBackupCodes)} className="btn btn-secondary">
                {showBackupCodes ? 'Hide Backup Codes' : 'Show Backup Codes'}
              </button>
              {showBackupCodes && (
                <div>
                  <h4>Backup Codes</h4>
                  <ul>
                    {backupCodes.map((code, index) => (
                      <li key={index}>{code}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="security-section">
          <h3>Security Logs</h3>
          <ul className="security-logs">
            {securityLogs.map((log, index) => (
              <li key={index}>
                <span className="log-date">{log.date}</span> - <span className="log-activity">{log.activity}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="security-section">
          <h3>Active Sessions</h3>
          <ul className="active-sessions">
            {activeSessions.map((session, index) => (
              <li key={index}>
                <span className="device">{session.device} ({session.location})</span>
                {session.active ? (
                  <div>
                    <span className="status active">Active</span>
                    <button onClick={() => logoutSession(session.device)} className="btn btn-danger">Logout</button>
                  </div>
                ) : (
                  <span className="status inactive">Inactive</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Security;
