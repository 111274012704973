import React, { useEffect, useState } from "react";
import "./Profile.scss";
import "../../App.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import UserProfile from "../../assets/user.jpeg";
import { FiLoader } from "react-icons/fi";
import DeleteModal from "../Modals/DeleteModal";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

function Profile({ showSidebar, active, closeSidebar }) {
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { fullName, surname } = useSelector((state) => state.user.userData) || {};
  const loadingFromStore = useSelector((state) => state.user.loading);

  useEffect(() => {
    setLoading(loadingFromStore);
  }, [loadingFromStore]);

  return (
    <div className="profile">
      {loading && (
        <div className="overlay">
          <FiLoader className="loading-spinner" />
        </div>
      )}
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="profile_container">
        <Navbar showSidebar={showSidebar} />
        <div className="top">
          <div className="user_info">
            <div className="profile_pic">
              <img src={UserProfile} alt="User Profile" />
            </div>
            <div className="text">
              <span>Fullname:</span>
              <div className="text_item">{fullName || 'Loading...'}</div>
              <span>Surname:</span>
              <div className="text_item">{surname || 'Loading...'}</div>
              <span>Status:</span>
            <div className="text_item">{surname || 'Not Verified'}</div>
            
            </div>
           
          </div>
        </div>
        <Link to="/refer">
          <button className="form_btn_new">
            Referral
          </button>
        </Link>

        <Link to="/chat">
          <button className="form_btn_new">
            Chat to us
          </button>
        </Link>

       
        <div className="management_section">
       
          <div className="management_options">
            <Link to="/edit-profile" className="management_option">
              <span>Edit Profile</span>
            </Link>
            <Link to="/security" className="management_option">
              <span>Security</span>
            </Link>
            <Link to="/verification" className="management_option">
              <span>Account Verification</span>
            </Link>
            <button className="form_btn_new" onClick={() => setIsDeleteModalOpen(true)}>
          Delete Account
        </button>
        
          </div>
        </div>

        
      </div>
      {isDeleteModalOpen && (
        <DeleteModal onClose={() => setIsDeleteModalOpen(false)} />
      )}
    </div>
  );
}

export default Profile;
