import React, { useState, useEffect, useRef } from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import './chatbox.scss';

const Chatbox = ({showSidebar, active, closeSidebar}) => {
  const [messages, setMessages] = useState([
    { text: 'Hello! How can I assist you today?', sender: 'bot' }
  ]);
  const [newMessage, setNewMessage] = useState('');
  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const userMessage = { text: newMessage, sender: 'user' };
    setMessages([...messages, userMessage]);
    setNewMessage('');

    setTimeout(() => {
      const botReply = { text: 'Thank you for your message, I will get back to you shortly.', sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botReply]);
    }, 1000);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div className="chatbox-wrapper">
        <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="chatbox-container">
      <Navbar showSidebar={showSidebar} />
        <div className="chatbox-header">
          <h3>Customer Support</h3>
        </div>
        <div className="chatbox-messages">
          {messages.map((message, index) => (
            <div key={index} className={`chatbox-message ${message.sender === 'user' ? 'user' : 'bot'}`}>
              {message.text}
            </div>
          ))}
          <div ref={messageEndRef} />
        </div>
        <div className="chatbox-input-container">
          <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
