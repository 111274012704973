import React, { useState } from "react";
import axios from "axios";
import "./Deposit.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import url from "../../components/endpoint";

const Deposit = ({ showSidebar, active, closeSidebar }) => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [DepositMethod, setDepositMethod] = useState("");

  const handleDepositMethod = (method) => {
    setDepositMethod(method);
  };

  const token = localStorage.getItem("token");

 const handleDeposit = () => {
  setLoading(true);

  if (!token) {
    toast.error("Token not found, please log in again.");
    setLoading(false);
    return;
  }

  if (isNaN(amount) || amount <= 0) {
    toast.error("Invalid amount");
    setLoading(false);
    return;
  }

  if (amount < 10) {
    toast.error("Minimum amount is R10");
    setLoading(false);
    return;
  }

  if (amount > 1000) {
    toast.error("Maximum amount is R1000");
    setLoading(false);
    return;
  }


  toast.promise(
    axios.get(`${url}/api/payment/process?amount=${amount}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    {
      pending: "Processing your deposit...",
      success: {
        render({ data }) {
          setLoading(false);
          if (data.status === 200) {
            toast.success("Redirecting to payment page...");
           
            window.location.href = data.data.link;
          }
          return "";
        },
      },
      error: {
        render({ data }) {
          setLoading(false);
          return ` ${data.response?.data?.error || "Something went wrong!"}`;
        },
      },
    }
  );
};


  const handleDepositPaypal = () => {
    setLoading(true);

    if (!token) {
      toast.error("Token not found, please log in again.");
      setLoading(false);
      return;
    }
     

    if (isNaN(amount) || amount <= 0) {
      toast.error("Invalid amount");
      setLoading(false);
      return;
    }

    if (amount < 10) {
      toast.error("Minimum amount is R10");
      setLoading(false);
      return;
    }

    if (amount > 1000) {
      toast.error("Maximum amount is R1000");
      setLoading(false);
      return;
    }

    const requestBody = {
      amount: parseFloat(amount),
    };

    toast.promise(
      axios.post(`${url}/deposit/paypalDeposit`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      {
        pending: "Processing your deposit...",
        success: {
          render({ data }) {
            setLoading(false);
            if (data.status === 200) {
              toast.success("Redirecting to payment page...");
              window.location.href = data.data.paymentLink; 
            }
            return "";
          },
        },
        error: {
          render({ data }) {
            setLoading(false);
            return ` ${data.response?.data?.error || "Something went wrong!"}`;
          },
        },
      }
    );
  };

  return (
    <div className="deposit">
      <ToastContainer />
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="deposit_container">
        <Navbar showSidebar={showSidebar} />
        <div className="content">
          <div className="middle">
            <div className="deposit_form">
              <div className="withdrawal-buttons">
                {DepositMethod === "" && (
                  <>
                    <button
                      className={`form_btn ${DepositMethod === "bank" ? "active" : ""}`}
                      onClick={() => handleDepositMethod("bank")}
                    >
                      Bank Deposit
                    </button>
                    <button
                      className={`form_btn ${DepositMethod === "paypal" ? "active" : ""}`}
                      onClick={() => handleDepositMethod("paypal")}
                    >
                      PayPal Deposit
                    </button>
                  </>
                )}
              </div>

              {DepositMethod === "bank" && (
                <>
                  <h2>
                    <b>Safe and Secure:</b>
                  </h2>
                  <div>
                    <label>Deposit Amount</label>
                    <br />
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      inputMode="numeric"
                    />
                  </div>
                  <button
                    className="form_btn"
                    onClick={handleDeposit}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Make Payment"}
                  </button>
                </>
              )}

              {DepositMethod === "paypal" && (
                <>
                  <h2>
                    <b>Deposit with PayPal:</b>
                  </h2>
                  <div>
                    <label>Amount</label>
                    <br />
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      inputMode="numeric"
                    />
                  </div>
                  <button
                    className="form_btn"
                    onClick={()=>handleDepositPaypal()}
                    
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Proceed"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
