import axios from 'axios';
import url from '../../components/endpoint';

export const fetchUserData = (urlToken) => async (dispatch) => {
    try {
        dispatch({ type: 'USER_FETCH_REQUEST' });

        const response = await axios.get(`${url}/api/User/data`, {
            headers: {
                Authorization: `Bearer ${urlToken}`,
              },
        });

        dispatch({ type: 'USER_FETCH_SUCCESS', payload: response.data });
    } catch (error) {
        console.log(error);
        dispatch({ type: 'USER_FETCH_FAILURE', payload: error.message });
    }
};


export const clearError = () => {
    return {
        type: 'USER_FETCH_CLEAR_ERROR'
    };
};
