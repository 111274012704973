import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { motion } from 'framer-motion'; 
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Home.scss";
import url from "../../components/endpoint";

const Home = ({ showSidebar, active, closeSidebar }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(`${url}/api/games/games`);
        setGames(response.data.games);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching games:', error);
        setLoading(false); 
      }
    };

    fetchGames();
  }, []);

  return (
    <div className="home">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="home_container">
        <Navbar showSidebar={showSidebar} />

        <div className="scrollview">
          {loading ? (
           
            <>
              {[...Array(4)].map((_, index) => (
                <div className="skeleton_card" key={index}>
                  <Skeleton height={150} width={250} className="skeleton_image" />
                  <Skeleton count={2} />
                </div>
              ))}
            </>
          ) : (
            games.map((game) => (
              <motion.div 
                key={game.id} 
                className="game_card"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={game.image} alt={game.name} className="game_image" />
                <div className="game_info">
                  <h3>{game.name}</h3>
                  <p>Min: R{game.min}</p>
                  <a href={game.link} className="play_button" target="_blank" rel="noopener noreferrer">
                    Play
                  </a>
                </div>
              </motion.div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
