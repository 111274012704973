import React, { useState } from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import './verification.scss';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import url from "../../components/endpoint";

const Verification = ({ showSidebar, active, closeSidebar }) => {
  const [bankStatement, setBankStatement] = useState(null);
  const [message, setMessage] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');

  const { email } = useSelector((state) => state.user.userData) || {};

  const handleFileChange = (e) => {
    setBankStatement(e.target.files[0]);
  };

  const token = localStorage.getItem("token");

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

 

    if (!token) {
      toast.error("No token found. Please log in again.");
      setLoading(false);
      return;
    }

  

    if (!email) {
      toast.error("Email not available.");
      setLoading(false);
      return;
    }

   
    toast.promise(
      axios.get(`${url}/otp/emailVerification`, {
        headers: {
          Authorization: `Bearer ${token}`, 
          userEmail: email  
        }
      }),
      {
        pending: "Generating your OTP...",
        success: {
          render({ data }) {
            setLoading(false);
            setOtpSent(true);
            setMessage('OTP sent to your email. Please check your inbox.');
            return "OTP sent to your email. Please check your inbox.";
          },
        },
        error: {
          render({ data }) {
            setLoading(false);
            const errorMessage = data.response?.data?.error || "Something went wrong!";
            
            return errorMessage;
          },
        },
      }
    ).catch((error) => {
      setLoading(false);
      console.error("Error in OTP generation:", error);
      toast.error("Failed to generate OTP. Please try again.");
    });
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };



  const handleVerifyOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

   
    toast.promise(
      axios.post(`${url}/otp/verifyOTP`, {
        userEmail: email,
        otp: otp
      }, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      }),
      {
        pending: 'Verifying your email...',
        success: {
          render({ data }) {
            setLoading(false);
            setOtpVerified(true);
            setMessage('OTP verified successfully. Please upload your bank statement.');
            return "OTP verified successfully!";
          }
        },
        error: {
          render({ data }) {
            setLoading(false);
            const errorMessage = data.response?.data?.error || "Failed to verify OTP.";
            setMessage(errorMessage);
            return errorMessage;
          }
        }
      }
    ).catch((error) => {
      setLoading(false);
      console.error("Error in OTP verification", error);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('Verification submitted successfully!');
  };

  return (
    <div className="verification-wrapper">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="verification-container">
        <Navbar showSidebar={showSidebar} />
        <h2>Verification Required</h2>
        <p>Please verify your email and upload a bank statement to proceed.</p>

        <form onSubmit={otpVerified ? handleSubmit : otpSent ? handleVerifyOtp : handleEmailSubmit} className="verification-form">
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email || ''}
              readOnly
              required
              placeholder="Enter your email"
            />
          </div>

          {otpSent && !otpVerified && (
            <>
              <div className="form-group">
                <label htmlFor="otp">Enter OTP</label>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={handleOtpChange}
                  required
                  placeholder="Enter OTP"
                  className="otp-input"
                />
              </div>
            </>
          )}

          {otpVerified && (
            <>
              <div className="form-group">
                <label htmlFor="bank-statement">Upload Bank Statement (PDF, JPEG, PNG)</label>
                <input
                  type="file"
                  id="bank-statement"
                  accept=".pdf,.jpeg,.png"
                  onChange={handleFileChange}
                  required
                />
              </div>
            </>
          )}

          <button type="submit" className="btn btn-primary">
            {otpVerified ? 'Submit Verification' : otpSent ? 'Verify Email' : 'Send OTP'}
          </button>
        </form>

        {message && <div className="verification-message">{message}</div>}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Verification;
